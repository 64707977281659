.back-color {
  background-color: #337ac0;
}

.section-about {
  color: #fff;
  text-align: center;
  padding: 2rem 0 8rem 0;
}
.section-about p {
  color: #fff;
  font-size: 3rem;
  line-height: 5rem;
  font-weight: 500;
  padding: 8rem 30rem;
  text-align: center;
}
/**************************/
/* BELOW 193em             */
/**************************/
@media (max-width: 193em) {
  .back-color {
    background-color: #337ac0;
  }

  .section-about {
    color: #fff;
    text-align: center;
    padding: 2rem 0 8rem 0;
  }
  .section-about p {
    color: #fff;
    font-size: 2.5rem;
    line-height: 4rem;
    font-weight: 500;
    padding: 6rem 20rem;
    text-align: center;
  }
}
/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
  .section-about {
    color: #fff;
    text-align: center;
    padding: 2rem 0 6rem 0;
  }

  .section-about p {
    font-size: 2.1rem;
    padding: 1rem 13rem 4rem 13rem;
  }
}

/******************************/
/* BELOW 60em  - small tablet */
/******************************/

@media (max-width: 60em) {
  .section-about {
    padding: 0 0 4rem 0;
  }
  .section-about p {
    color: #fff;
    padding: 1rem 2.5rem 2rem 2.5rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}
