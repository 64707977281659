.testimonials-section {
  margin: 2rem 4rem;
  padding: 2rem 4rem;
  background-color: #fff;
  text-align: center;
}

.container {
  width: 80%;
  margin: 0 auto 4rem auto;
}
.testimonials-section h2 {
  padding: 4rem 0 8rem 0;
  background-color: #fff;
  font-size: 5rem;
  font-weight: 600;
  color: #666;
  text-align: center;
}
.row {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  justify-content: center;
  column-gap: 12rem;
  row-gap: 6rem;
}
.row p {
  font-size: 2.5rem;
  color: #777;
  font-weight: 500;
}

/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
  .testimonials-section h2 {
    padding: 0 0 4rem 0;
    font-size: 3.5rem;
  }

  .container {
    width: 100%;
    margin: 0 auto 0 auto;
  }

  .row p {
    font-size: 2rem;
    color: #777;
    font-weight: 500;
  }
  .row {
    column-gap: 5rem;
    row-gap: 3rem;
  }
}

/******************************/
/* BELOW 60em small tablet    */
/******************************/

@media (max-width: 60em) {
  .container {
    width: 90%;
    margin: 0 auto;
  }
  .testimonials-section {
    margin: 0;
    padding: 1rem 1rem 1rem 1rem;
  }
  .row {
    grid-template-columns: 1fr;
  }
  .testimonials-section h2 {
    font-size: 2.8rem;
    padding: 1rem 1rem 2rem 1rem;
  }
  .row p {
    font-size: 1.6rem;
  }
  .row {
    gap: 2rem;
  }
}

/******************************/
/* BELOW 50em small phone     */
/******************************/

@media (max-width: 50em) {
  .testimonials-section h2 {
    font-size: 2rem;
  }
}
