* {
  margin: 0;
  padding: 0;
  font-family: "Athiti", sans-serif;
}
html {
  background-color: #337ac0;
}

/* h1,
h2,
h3,
h4 {
  font-size: 4.5rem;
  color: #666;
  font-weight: 600;
} */

/* p {
  font-size: 3rem;
  line-height: 4rem;
  color: #777;
  font-weight: 500;
} */
