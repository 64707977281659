.contact-container {
  background-color: #337ac0;
  padding: 6rem;
}
.contact-form {
  background-color: white;
  width: 70%;
  margin: 0 auto;
  padding: 4rem;
}
.contact-form h1 {
  padding-bottom: 2rem;
  font-size: 4rem;
  color: #666;
  font-weight: 600;
  text-align: center;
}
.contact-form p {
  display: inline-block;
  font-size: 2.2rem;
  color: #777;
  font-weight: 500;
  text-align: center;
  padding: 2rem 10rem;
}

.contact-label {
  display: block;
  margin: 2rem 3rem 0rem 2rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: #666;
}
.contact-label span {
  display: flex;
  font-size: 2.5rem;
  font-weight: 600;
  color: #666;
  margin: 1rem 2rem 0.5rem 2rem;
}
.contact-input {
  width: 98%;
  margin: 0 0 0 2rem;
}
.button-section {
  padding-top: 4rem;
  display: flex;
  gap: 3rem;
}

.message-button {
  width: 20rem;
  align-self: right;
  margin: 2rem 1rem 4rem auto;
  font-size: 2.5rem;
  font-weight: 600;
  padding: 2rem;
  border: none;
  background-color: #469998;
  color: #fff;
  text-align: center;
  border-radius: 1.3rem;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #243b81;
}
.cancel-button {
  width: 20rem;
  align-self: left;
  margin: 2rem auto 4rem 1rem;
  font-size: 2.5rem;
  font-weight: 600;
  padding: 2rem;
  border: none;
  background-color: #469998;
  color: #fff;
  text-align: center;
  border-radius: 1.3rem;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #243b81;
}

.message-button:hover {
  background-color: #7fbcbb;
  color: #fff;
}
.cancel-button:hover {
  background-color: #7fbcbb;
  color: #fff;
}

.finish-email h1 {
  padding: 2rem 0 1rem;
  font-size: 3rem;
  color: #777;
  text-align: center;
}

.finish-button {
  align-self: center;
  width: auto;
  margin: 2rem auto 2rem auto;
  font-size: 3rem;
  font-weight: 600;
  padding: 2rem 4rem;
  border: none;
  background-color: #469998;
  color: #fff;
  text-align: center;
  border-radius: 1.3rem;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #243b81;
}

.finish-button:hover {
  background-color: #7fbcbb;
  color: #fff;
}

.error {
  text-align: center;
}

.error p {
  color: orangered;
}

/**************************/
/* BELOW 193em             */
/**************************/
@media (max-width: 193em) {
  .contact-form {
    background-color: white;
    width: 70%;
    margin: 0 auto;
    padding: 4rem;
  }
}

/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
  .contact-form h1 {
    font-size: 3.5rem;
  }
  .contact-form {
    width: 80%;
    margin: 0 auto;
    padding: 4rem 2rem;
  }

  .contact-form p {
    display: inline-block;
    font-size: 2rem;
    padding: 2rem 4rem;
  }

  .message-button {
    width: 19rem;
    font-size: 2.2rem;
    padding: 1rem 1rem;
  }
  .cancel-button {
    width: 19rem;
    font-size: 2.2rem;
    padding: 1rem 1rem;
  }
  .finish-button {
    font-size: 2.2rem;
    padding: 1rem 1rem;
  }
}

/**************************/
/* BELOW 60em             */
/**************************/

@media (max-width: 60em) {
  .contact-container {
    padding: 1rem 2rem 1rem 2rem;
    margin: 0;
  }

  .contact-form h1 {
    padding-top: 2rem;
    font-size: 2.5rem;
  }
  .contact-form {
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .contact-label {
    margin: 1rem 1rem 0rem 1rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #666;
  }
  .contact-label span {
    display: flex;
    font-size: 1.8rem;
    font-weight: 600;
    color: #666;
    margin: 0.5rem 1rem 0.5rem 0rem;
  }
  .contact-input {
    width: 100%;
    margin: 0;
  }

  .contact-form p {
    display: inline-block;
    font-size: 1.6rem;
    padding: 1rem 1rem;
  }

  .message-button {
    width: 16rem;
    font-size: 1.5rem;
    padding: 1rem 1rem;
  }
  .cancel-button {
    width: 16rem;
    font-size: 1.5rem;
    padding: 1rem 1rem;
  }
  .finish-button {
    font-size: 1.5rem;
    padding: 1rem 1rem;
  }
  .button-section {
    padding: 0 2rem 0 2rem;
    display: flex;
    gap: 1rem;
  }
}

/**************************/
/* BELOW 40em             */
/**************************/
@media (max-width: 50em) {
  .message-button {
    width: 10rem;
    font-size: 1rem;
    padding: 1rem 1rem;
  }
  .cancel-button {
    width: 10rem;
    font-size: 1rem;
    padding: 1rem 1rem;
  }
  .finish-button {
    font-size: 1rem;
    padding: 1rem 1rem;
  }
  .button-section {
    padding: 0;
    display: flex;
    gap: 1rem;
  }
}
