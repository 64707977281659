
.card{
  display:inline-block;
  margin: 1rem auto 2rem auto;
}

.row{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.leftArrow{
  display:flex;
  color:white;
  font-size: 8rem;
  margin-right: 4rem;
}

.rightArrow{
  display:flex;
  color:white;
  font-size: 8rem;
  margin-left: 4rem;
}


.rightArrow:hover,
.leftArrow:hover{
  color:#7fbcbb;
  cursor: pointer;
}



/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {

  .leftArrow{
    font-size: 5rem;
    margin-right: 2rem;
  }
  .rightArrow{
    
    font-size: 5rem;
    margin-left: 2rem;
  }
  
 
}

/**************************/
/* BELOW 60em             */
/**************************/

@media (max-width: 60em) {

  .leftArrow{
    font-size: 4rem;
    margin-right: 1rem;
  }
  .rightArrow{
    
    font-size: 4rem;
    margin-left: 1rem;
  }
  
 
}


/**************************/
/* BELOW 40em             */
/**************************/

@media (max-width: 40em) {

  .leftArrow{
    font-size: 3rem;
    margin-right: .2rem;
  }
  .rightArrow{
    
    font-size: 3rem;
    margin-left: .2rem;
  }
  
 
}