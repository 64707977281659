.circleRow{
    display: inline-block; 
}
.circle{
    display: flex;
    margin: 2rem 4rem;
    flex-direction: row;;
    justify-content: space-evenly;
    border: .2rem solid white;
    border-radius: 50%;
    height:3rem;
    width:3rem;
}

.selectedCircle{
    background-color: white;
    border:none;
    
}

.circle:hover{
    background-color:#7fbcbb;
    cursor: pointer;
}

.selectedCircle:hover{
    background-color:#7fbcbb;
    cursor: pointer;
}

/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
    .circle{
       
        margin: 2rem 3rem;
        height:2rem;
        width:2rem;
    }
}