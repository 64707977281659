.serv-section {
  background-color: #fff;
  color: #2b2b2b;
  margin: 0 auto;
  padding: 4rem 0 8rem 0;
}
.container {
  background-color: #fff;
  text-align: center;
}
.services-list ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}
.service-title {
  background-color: #fff;
  font-size: 5rem;
  color: #666;
  font-weight: 600;
  margin: 0 auto;
  padding: 4rem 0 6rem 0;
  text-align: center;
}

.service-row {
  display: flex;
  background-color: #fff;
  margin: 0 auto;
  align-content: center;
  justify-content: center;
  text-decoration: none;
  color: #666;
  gap: 1rem;
  flex-direction: row;
}

.icon {
  margin: 1rem 1rem 0 0;
  display: flex;
  height: 4rem;
  width: 4rem;
  stroke: #f2b922;
  stroke-width: 3;
}

.description {
  display: flex;
}
.description p {
  font-size: 3.5rem;
  color: #777;
  font-weight: 500;
  font-style: italic;
}

/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
  .serv-section {
    padding: 3rem 0 3rem 0;
  }
  .service-title {
    display: inline-block;
    font-size: 3.2rem;
    padding: 0 1.5rem 3rem 1.5rem;
  }
  .description p {
    font-size: 2.5rem;
  }

  .icon {
    width: 2.6rem;
    height: 2.6rem;
  }
  .services-list ul {
    gap: 1rem;
  }
}

/*****************************/
/* BELOW 60em (Small tablet) */
/*****************************/

@media (max-width: 60em) {
  .serv-section {
    padding: 1rem 1.3rem 1rem 1.3rem;
  }
  .service-title {
    font-size: 2.3rem;
    padding: 1rem 1rem 2.5rem 1rem;
  }

  .services-list ul {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 0 1rem;
  }

  .service-row {
    display: grid;
    grid-template-columns: 3rem 1fr;
    color: #666;
  }

  .description p {
    text-align: left;
    font-size: 1.8rem;
  }

  .icon {
    width: 2.2rem;
    height: 2.2rem;
    margin: 0.4rem 0 0 0;
  }
}

/*****************************/
/* BELOW 50em (Small phone)  */
/*****************************/

@media (max-width: 50em) {
  .service-title {
    font-size: 2rem;
  }
}
