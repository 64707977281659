.backColor {
  background-color: #337ac0;
}
.header {
  max-width: 200rem;
  padding: 12rem 0 4rem 0;
  margin: 0 auto;
}

.container {
  display: grid;
  grid-template-columns: 1.3fr 1.8fr;
  column-gap: 10rem;
  justify-content: left;
  align-items: center;
}
.titles {
  z-index: 0;
  color: white;
}
.title {
  font-size: 7rem;
  font-weight: 600;
}

.logoTag {
  font-size: 5.5rem;
  font-weight: 500;
  font-style: italic;
}

.logoImg {
  z-index: 1;
  height: 19rem;
  border-radius: 50%;
  box-shadow: 0rem -1rem 18rem 2.7rem #f2b932;
  justify-self: right;
  margin-right: 2rem;
}

/**************************/
/* BELOW 193em             */
/**************************/
@media (max-width: 193em) {
  .backColor {
    background-color: #337ac0;
  }
  .header {
    max-width: 200rem;
    padding: 12rem 0 4rem 0;
    margin: 0 auto;
  }

  .container {
    display: grid;
    grid-template-columns: 1.3fr 1.8fr;
    column-gap: 10rem;
    justify-content: left;
    align-items: center;
  }
  .titles {
    z-index: 0;
    color: white;
  }
  .title {
    font-size: 5rem;
    font-weight: 600;
  }

  .logoTag {
    font-size: 4rem;
    font-weight: 500;
    font-style: italic;
  }

  .logoImg {
    z-index: 1;
    height: 17rem;
    border-radius: 50%;
    box-shadow: 0rem -1rem 18rem 2.7rem #f2b932;
    justify-self: right;
    margin-right: 2rem;
  }
}
/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
  .logoImg {
    margin-right: 3rem;
    height: 13rem;
  }

  .header {
    padding: 6rem 0 2rem 0;
    margin: 0 auto;
  }

  .container {
    column-gap: 2rem;
    grid-template-columns: 1.2fr 2fr;
  }

  .title {
    color: #fff;
    font-size: 3.4rem;
  }

  .logoTag {
    font-size: 2.8rem;
  }
}

/*******************************/
/* BELOW 60em (Smaller tablet) */
/*******************************/
@media (max-width: 60em) {
  .header {
    padding: 4rem 0 2rem 0;
    margin: 0 auto;
  }

  .logoImg {
    margin: 2rem auto;
    height: 12rem;
  }
  .container {
    grid-template-columns: 1fr;
  }

  .title {
    text-align: center;
    font-size: 3.5rem;
    line-height: 6rem;
  }

  .logoTag {
    text-align: center;
    font-size: 2.5rem;
    line-height: 2rem;
  }
}

/*******************************/
/* BELOW 50em (Smaller phone) */
/*******************************/
@media (max-width: 50em) {
  .logoImg {
    margin: 2rem auto;
    height: 10rem;
  }
  .container {
    grid-template-columns: 1fr;
  }

  .title {
    text-align: center;
    font-size: 2.2rem;
    line-height: 2.5rem;
    padding-bottom: 1rem;
  }

  .logoTag {
    text-align: center;
    font-size: 1.7rem;
    line-height: 1.7rem;
  }
}
