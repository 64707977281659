.pf-section {
  margin: 0 auto;
  background-color: #243b81;
  text-align: center;
  padding: 6rem 0;
}

.pf-section h2 {
  background-color: #243b81;
  margin: 0 auto;
  font-size: 5rem;
  font-weight: 600;
  color: #fff;
  padding: 3rem 0 7rem 0;
  text-align: center;
}



/**************************/
/* BELOW 100em             */
/**************************/

@media (max-width: 100em) {
  .pf-section {
    padding: 3rem 0;
  }

  .pf-section h2 {
    font-size: 3.5rem;
    padding: 1rem 0 4rem 0;
  }
}

/**************************/
/* BELOW 60em             */
/**************************/

@media (max-width: 60em) {
  .pf-section {
    padding: 2rem 0 0 0;
  }
  .pf-section h2 {
    font-size: 2.8rem;
    padding: 0 0 2rem 0;
  }
}

/**************************/
/* BELOW 50em             */
/**************************/

@media (max-width: 50em) {
  .pf-section h2 {
    font-size: 2rem;
  }
}
