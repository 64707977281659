.button-class {
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  height: auto;
  width: auto;
  background-color: #469998;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 1.3rem;
  font-size: 3.5rem;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #243b81;
}

.button-class:hover {
  background-color: #7fbcbb;
  color: #fff;
  /* transform: scale(1.2); */
}

.button-class-permanent {
  position: fixed;
  top: 8rem;
  right: 8rem;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  height: auto;
  width: auto;
  background-color: #469998;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 1.3rem;
  font-size: 3.5rem;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #243b81;
  z-index: 100;
}

.button-class-permanent:hover,
.button-class:hover {
  background-color: #7fbcbb;
  color: #fff;
}

/**************************/
/* BELOW 193em             */
/**************************/
@media (max-width: 193em) {
  .button-class {
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
    height: auto;
    width: auto;
    background-color: #469998;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 1.3rem;
    font-size: 2.5rem;
    box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #243b81;
  }

  .button-class:hover {
    background-color: #7fbcbb;
    color: #fff;
    /* transform: scale(1.2); */
  }

  .button-class-permanent {
    position: fixed;
    top: 4rem;
    right: 4rem;
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
    height: auto;
    width: auto;
    background-color: #469998;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 1.3rem;
    font-size: 2.5rem;
    box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #243b81;
    z-index: 100;
  }

  .button-class-permanent:hover,
  .button-class:hover {
    background-color: #7fbcbb;
    color: #fff;
  }
}
/*******************************/
/* BELOW 110em (Smaller laptop)*/
/*******************************/
@media (max-width: 110em) {
  .button-class {
    font-size: 2rem;
    padding: 1rem 1.5rem;
    height: 5rem;
    width: 12rem;
  }
  .button-class-permanent {
    font-size: 2rem;
    position: fixed;
    top: 2rem;
    right: 2rem;
    padding: 1rem;
    height: 5rem;
    width: 12rem;
  }
}

/*******************************/
/* BELOW 80em (Smaller tablet) */
/*******************************/
@media (max-width: 80em) {
  .button-class {
    padding: 1rem;
    height: 4.3rem;
    width: 10rem;
    font-size: 1.5rem;
  }
  .button-class-permanent {
    position: fixed;
    top: 2rem;
    right: 2rem;
    padding: 1rem;
    height: 4.3rem;
    width: 10rem;
    font-size: 1.5rem;
  }
}

/*******************************/
/* BELOW 50em (Smaller phone) */
/*******************************/
@media (max-width: 50em) {
  .button-class {
    padding: 0.75rem;
    height: auto;
    width: auto;
    font-size: 1.3rem;
  }
  .button-class-permanent {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 0.75rem;
    height: auto;
    width: auto;
    font-size: 1.3rem;
  }
}
