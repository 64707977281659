.section {
  background-color: #337ac0;
  color: #ccc;
  text-align: center;
  padding: 4rem 0 4rem 0;
}

.footer-logo {
  margin: 0 auto;
}

.email-section {
  display: flex;
  justify-content: center;
  margin: 0 auto 2rem 0;
}
.phone-section {
  display: flex;
  justify-content: center;
  margin: 0 auto 3rem 0;
}

.small-icon {
  height: 3rem;
  margin-top: 1rem;
  margin-right: 2rem;
}
.small-logo {
  display: flex;
  margin: 1rem auto;
  height: 10rem;
  border-radius: 50%;
  box-shadow: 0rem -0.5rem 6rem 1.25rem #f2b932;
}
.email,
.phone {
  font-size: 3rem;
  color: #ccc;
}

a:hover {
  color: #243b81;
}
.section p {
  color: #ccc;
  font-size: 3rem;
  margin: 2rem 0 0 0;
}

/**************************/
/* BELOW 193em             */
/**************************/
@media (max-width: 193em) {
  .section {
    background-color: #337ac0;
    color: #ccc;
    text-align: center;
    padding: 4rem 0 4rem 0;
  }

  .footer-logo {
    margin: 0 auto;
  }

  .email-section {
    display: flex;
    justify-content: center;
    margin: 0 auto 2rem 0;
  }
  .phone-section {
    display: flex;
    justify-content: center;
    margin: 0 auto 3rem 0;
  }

  .small-icon {
    height: 1.8rem;
    margin-top: 0.5rem;
    margin-right: 1rem;
  }
  .small-logo {
    display: flex;
    margin: 1rem auto;
    height: 7rem;
    border-radius: 50%;
    box-shadow: 0rem -0.5rem 6rem 1.25rem #f2b932;
  }
  .email,
  .phone {
    font-size: 2rem;
    color: #ccc;
  }

  a:hover {
    color: #243b81;
  }
  .section p {
    color: #ccc;
    font-size: 2rem;
    margin: 2rem 0 0 0;
  }
}
/**********************************/
/* BELOW 100em small laptop size  */
/**********************************/

@media (max-width: 100em) {
  .footer-logo {
    margin: 0 auto;
  }
  .small-logo {
    height: 7rem;
  }

  .section {
    text-align: center;
  }

  .section p {
    font-size: 1.8rem;
    margin: 1rem 0 0 0;
  }

  .email-section {
    margin: 0 auto 1rem 0;
  }
  .phone-section {
    margin: 0 auto 2rem 0;
  }

  .email,
  .phone {
    font-size: 1.8rem;
  }
  .small-icon {
    height: 1.8rem;
    margin-top: 0.5rem;
    margin-right: 1rem;
  }
}

/**********************************/
/* BELOW 60em small tablet size  */
/**********************************/

@media (max-width: 60em) {
  .section {
    padding: 2rem 0 2rem 0;
  }
  .email,
  .phone {
    font-size: 1.8rem;
  }
  .section p {
    font-size: 1.8rem;
  }
  .small-logo {
    height: 6rem;
  }
}

/**********************************/
/* BELOW 50em small tablet size  */
/**********************************/

@media (max-width: 50em) {
  .section {
    padding: 1rem 0 1rem 0;
  }
  .email,
  .phone {
    font-size: 1.7rem;
  }
  .section p {
    font-size: 1.6rem;
  }

  .small-icon {
    height: 1.8rem;
    margin-top: 0.4rem;
    margin-right: 0.5rem;
  }
}
